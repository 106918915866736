import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Language from "../../helpers/language";
import Util from "../../helpers/util";

export const AnswerTypeBool = React.forwardRef((props, ref) => {
    const { answer, value, onChanged } = props;

    const is_answered = (value != undefined && value != null && Util.xstr(value) != '')
    const safe_value = Util.xbool( value )

    React.useImperativeHandle(ref, () => ({
        reset: () => {
        },
        attemptAnswer: () => {
            return false;
        }
    }));

    const calcResult = ( answer, safe_value ) => {
        const ans_correct = (answer && 'correct' in answer && answer.correct != undefined && answer.correct != null)? answer.correct: safe_value
        const is_correct = (Util.xbool(ans_correct) == Util.xbool(safe_value))

        return {
            ans_correct,
            is_correct,
        }
    }

    const handleChange = (e) => {
        const safe_value = e.target.value
        const { ans_correct, is_correct } = calcResult( answer, safe_value )

        onChanged(safe_value, is_correct, ans_correct.toString());
    };

    const klass = exp => {
        const { ans_correct, is_correct } = calcResult( answer, safe_value )

        //No answer? do nothing
        if ( !is_answered ) {
            return ""//"border border-1 border-drip7"
        }

        //If the answer isn't correct and this is the answer given, show it
        if ( !is_correct && safe_value == exp ) {
            return " daily-questions__" + exp + "--incorrect";
        }

        //No matter if the answer is correct or not, if this is the correct answer, mark it
        if ( ans_correct == exp ) {
            return " daily-questions__" + exp + "--correct";
        }

        return "";
    };

    return (
        <div className='daily-questions__true-or-false'>
            <button
                className={Util.classNames(
                    "daily-questions__true",
                    klass(true)
                )}
                value={true}
                onClick={handleChange}>
                {Language.getSentenceCase('true')}
            </button>
            <button
                className={Util.classNames(
                    "daily-questions__false",
                    "border border-1 border-drip7",
                    klass(false)
                )}
                value={false}
                onClick={handleChange}>
                {Language.getSentenceCase('false')}
            </button>
        </div>
    );
});
