import React, { useEffect, useRef, useState } from "react";
import ResultIMG from "../../assets/images/user-portal/result.svg";
import Categories from "../../components/user/categories";
import InputWithIcon from "../../components/input_with_Icon";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
//import { Spinner } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import {Drip7Input} from "../../components/drip7_input";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";

export const QuestionArchive = (props) => {
    const { showToast } = props;

    const ans_type = false;
    let tempKey = {};
    /*
        history.location.state && "answer_type" in history.location.state
            ? history.location.state.answer_type
            : false;
     */

    const MOST_RECENT = 1;
    const OLDEST = 2;
    const [openCategories, setOpenCategories] = useState(false);
    const [openOrder, setOpenOrder] = useState(false);

    const [state, setState] = useState({
        loaded: false,
        answer_key: [],
        answer_loaded: false,
        answer_type: ans_type,
        category_uid: "",
        order_id: MOST_RECENT,
        user_questions: [],
        categories: [],
        orders: [
            { id: MOST_RECENT, name: "most recent" },
            { id: OLDEST, name: "oldest" },
        ],
        search: "",
    });
    const {
        loaded,
        answer_type,
        category_uid,
        order_id,
        user_questions,
        categories,
        orders,
        search,
        answer_key,
        answer_loaded,
    } = state;

    const navigate = useRef(useNavigate());

    let category_lookup = {};
    let category;
    for (let i = 0; i < categories.length; i++) {
        const cat = categories[i];

        if (category_uid == cat.uid) {
            category = cat.name;
        }

        category_lookup[cat.uid] = cat;
    }

    //let order_title = orders.find(o => o.id == order_id).name;

    //Filter the questions
    let filtered_questions = [];
    if (answer_type == true || answer_type == false) {
        for (let i = 0; i < user_questions.length; i++) {
            const uq = user_questions[i];
            if (
                uq.answered_on > 0 &&
                uq.correct == answer_type &&
                (category_uid == "" || uq.category_uid == category_uid)
            ) {
                filtered_questions.push(uq);
            }
        }
    }

    //Un answered question only
    else {
        for (let i = 0; i < user_questions.length; i++) {
            const uq = user_questions[i];
            if (
                uq.answered_on <= 0 &&
                (category_uid == "" || uq.category_uid == category_uid)
            ) {
                filtered_questions.push(uq);
            }
        }
    }

    //sort the filtered questions
    let sorted_questions = filtered_questions.sort((a, b) => {
        if (order_id == MOST_RECENT) {
            return b.assigned_on - a.assigned_on;
        } else {
            return a.assigned_on - b.assigned_on;
        }
    });

    // finally, apply the search criteria
    let searched_questions = sorted_questions.filter(
        (q) =>
            search == "" || q.question.name.toLowerCase().indexOf(search) != -1
    );

    //Load all the avatars
    useEffect(() => {
        props.onPathChange("question-archive");
        //Loaded
        if (!loaded) {
            //Get the user's archive
            Util.fetch_js(
                "/client/question/archived/",
                {},
                (js) => {
                    setState({
                        ...state,
                        loaded: true,
                        user_questions: js.user_questions.reverse(),
                        categories: js.categories,
                    });
                },
                (err, code) => {
                    showToast(err, "failure");
                }
            );
        }
    }, []);

    useEffect(() => {
        if (loaded) {
            Util.fetch_js(
                "/client/question/list/",
                {},
                (js) => {
                    const sortData = async () => {
                        let tempKey = {};
                        const data = await js.user_questions.forEach((uq) => {
                            const rawAnswer =
                                uq &&
                                uq.question_content &&
                                uq.question_content.answer &&
                                uq.question_content.answer.correct !== null
                                    ? uq.question_content.answer.correct
                                    : null;
                            const output = { answer: "" };
                            if (rawAnswer === null) {
                                console.log(
                                    "The value is null.",
                                    uq.question_content
                                );
                                output.answer += "No answer recorded";
                            } else if (typeof rawAnswer == "boolean") {
                                output.answer += rawAnswer;
                            } else if (
                                typeof rawAnswer == "object" &&
                                rawAnswer.length <= 1
                            ) {
                                const capitalized =
                                    rawAnswer[0].charAt(0).toUpperCase() +
                                    rawAnswer[0].slice(1);
                                output.answer += capitalized;
                            } else if (
                                typeof rawAnswer == "object" &&
                                rawAnswer.length > 1
                            ) {
                                const tempAnswer = rawAnswer.join(", ");
                                const commaIndex = tempAnswer.lastIndexOf(",");
                                const correctPunc =
                                    tempAnswer.slice(0, commaIndex) +
                                    " or" +
                                    tempAnswer.slice(commaIndex + 1);
                                const capitalized =
                                    correctPunc.charAt(0).toUpperCase() +
                                    correctPunc.slice(1);
                                output.answer += capitalized;
                            } else if (
                                typeof rawAnswer == "number" ||
                                typeof rawAnswer == "string"
                            ) {
                                //this should always be valid but in case the answer is saved incorrectly in the admin we log the details
                                if (
                                    uq &&
                                    uq.question_content &&
                                    uq.question_content.answer &&
                                    uq.question_content.answer.answers &&
                                    uq.question_content.answer.answers[
                                        rawAnswer
                                    ] == undefined
                                ) {
                                    console.log(
                                        "failed question content",
                                        uq.question_content
                                    );
                                    console.log("raw answer", rawAnswer);
                                    output.answer == "No answer recorded";
                                } else {
                                    if (
                                        uq.question_content.answer.answers[
                                            rawAnswer
                                        ].includes("ll of the above") ||
                                        uq.question_content.answer.answers[
                                            rawAnswer
                                        ].includes("ll the above")
                                    ) {
                                        for (
                                            let i = 0;
                                            i <
                                            uq.question_content.answer.answers
                                                .length -
                                                1;
                                            i++
                                        ) {
                                            if (
                                                i ==
                                                uq.question_content.answer
                                                    .answers.length -
                                                    2
                                            ) {
                                                output.answer +=
                                                    " and " +
                                                    uq.question_content.answer
                                                        .answers[i];
                                            } else if (
                                                i ==
                                                uq.question_content.answer
                                                    .answers.length -
                                                    3
                                            ) {
                                                output.answer +=
                                                    uq.question_content.answer.answers[
                                                        i
                                                    ];
                                            } else {
                                                output.answer +=
                                                    uq.question_content.answer
                                                        .answers[i] + ", ";
                                            }
                                        }
                                    } else {
                                        output.answer +=
                                            uq.question_content.answer.answers[
                                                rawAnswer
                                            ];
                                    }
                                }
                            }
                            tempKey[uq.uid] =
                                output.answer || "No answer recorded";
                        });

                        setState({
                            ...state,
                            answer_key: tempKey,
                            answer_loaded: true,
                        });
                    };
                    sortData().catch(console.error);
                },
                (err, code) => {
                    console.log(err, "failure");
                }
            );
        }
    }, [loaded]);

    const handleChange = (e) => {
        setState({ ...state, [e.target.id]: e.target.value });
    };

    const handleAnswerType = (answer_type) => {
        setState({ ...state, answer_type });
    };

    const handleCategoryClear = () => {
        setOpenCategories((prev) => !prev);
        setState((prev) => ({ ...prev, category_uid: "" }));
    };

    const handleCreateQuiz = (user_question_uids) => {
        Util.fetch_js(
            "/client/question/create_quiz/",
            { user_question_uids },
            (js) => {
                showToast(
                    Language.getSentenceCase("Created pop quiz"),
                    "successful"
                );
                navigate.current("/drip");
            },
            (resp, code) => {
                showToast(
                    Language.getSentenceCase("Couldn't create quiz"),
                    "failure"
                );
            }
        );
    };

    const klassAnswerType = (at) => {
        if (answer_type == at) {
            return "central-container__filter-button central-container__filter-button--question-archive central-container__filter-button--selected";
        } else {
            return "central-container__filter-button central-container__filter-button--question-archive";
        }
    };

    return (
        <div className="user-portal__main-container user-portal__main-container--question-archive">
            <div className="badge-status__heading mobile-heading z-40">
                <h1>{Language.getTitleCase("question archive")}</h1>
            </div>

            <div className="central-container__filter-button-container central-container__filter-button-container--mobile central-container__filter-button-container--question-archive z-30">
                <div
                    className={klassAnswerType(false)}
                    onClick={() => handleAnswerType(false)}
                >
                    <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 2.21875V10.6562C0 11.1484 0.351562 11.5 0.84375 11.5H3.65625C4.11328 11.5 4.5 11.1484 4.5 10.6562V2.21875C4.5 1.76172 4.11328 1.375 3.65625 1.375H0.84375C0.351562 1.375 0 1.76172 0 2.21875ZM1.40625 9.25C1.40625 8.79297 1.75781 8.40625 2.25 8.40625C2.70703 8.40625 3.09375 8.79297 3.09375 9.25C3.09375 9.74219 2.70703 10.0938 2.25 10.0938C1.75781 10.0938 1.40625 9.74219 1.40625 9.25ZM10.9688 18.25C11.8125 18.25 13.5 17.9688 13.5 15.4023C13.5 13.9258 12.5859 13.082 12.3047 12.0625H15.8906C17.0508 12.0625 17.9648 11.1133 18 10.0234C18 9.39062 17.7188 8.72266 17.2969 8.30078C17.6484 7.49219 17.5781 6.33203 16.9805 5.52344C17.2617 4.60938 16.9805 3.48438 16.3828 2.88672C16.5586 2.25391 16.4883 1.72656 16.1719 1.30469C15.4688 0.285156 13.6758 0.25 12.1641 0.25H12.0586C10.3711 0.285156 9 0.882812 7.875 1.375C7.3125 1.62109 6.57422 1.9375 6.01172 1.9375C5.80078 1.97266 5.625 2.14844 5.625 2.35938V9.88281C5.625 9.98828 5.66016 10.1289 5.73047 10.1992C7.13672 11.5703 7.73438 13.0117 8.85938 14.1719C9.38672 14.6992 9.5625 15.4727 9.77344 16.2461C9.91406 16.8789 10.2305 18.25 10.9688 18.25Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                    <h3 className="central-container__filter-button-name">
                        {Language.getTitleCase("incorrect")}
                    </h3>
                </div>

                <div
                    className={klassAnswerType(true)}
                    onClick={() => handleAnswerType(true)}
                >
                    <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.65625 8.125H0.84375C0.351562 8.125 0 8.51172 0 8.96875V17.4062C0 17.8984 0.351562 18.25 0.84375 18.25H3.65625C4.11328 18.25 4.5 17.8984 4.5 17.4062V8.96875C4.5 8.51172 4.11328 8.125 3.65625 8.125ZM2.25 16.8438C1.75781 16.8438 1.40625 16.4922 1.40625 16C1.40625 15.543 1.75781 15.1562 2.25 15.1562C2.70703 15.1562 3.09375 15.543 3.09375 16C3.09375 16.4922 2.70703 16.8438 2.25 16.8438ZM13.5 3.13281C13.5 0.53125 11.8125 0.25 10.9688 0.25C10.2305 0.25 9.91406 1.65625 9.77344 2.28906C9.5625 3.0625 9.38672 3.83594 8.85938 4.36328C7.73438 5.52344 7.13672 6.96484 5.73047 8.33594C5.66016 8.44141 5.625 8.54688 5.625 8.65234V16.1758C5.625 16.3867 5.80078 16.5625 6.01172 16.5977C6.57422 16.5977 7.3125 16.9141 7.875 17.1602C9 17.6523 10.3711 18.25 12.0586 18.25H12.1641C13.6758 18.25 15.4688 18.25 16.1719 17.2305C16.4883 16.8086 16.5586 16.2812 16.3828 15.6484C16.9805 15.0508 17.2617 13.9258 16.9805 13.0117C17.5781 12.2031 17.6484 11.043 17.2969 10.2344C17.7188 9.8125 18 9.14453 17.9648 8.51172C17.9648 7.42188 17.0508 6.4375 15.8906 6.4375H12.3047C12.5859 5.45312 13.5 4.60938 13.5 3.13281Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                    <h3 className="central-container__filter-button-name">
                        {Language.getTitleCase("correct")}
                    </h3>
                </div>

                <div
                    className={klassAnswerType(null)}
                    onClick={() => handleAnswerType(null)}
                >
                    <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.125 15C1.125 15.6328 1.61719 16.125 2.25 16.125H15.75C16.3477 16.125 16.875 15.6328 16.875 15V4.875H1.125V15ZM6.75 7.54688C6.75 7.33594 6.92578 7.125 7.17188 7.125H10.8281C11.0391 7.125 11.25 7.33594 11.25 7.54688V7.82812C11.25 8.07422 11.0391 8.25 10.8281 8.25H7.17188C6.92578 8.25 6.75 8.07422 6.75 7.82812V7.54688ZM16.875 0.375H1.125C0.492188 0.375 0 0.902344 0 1.5V3.1875C0 3.50391 0.246094 3.75 0.5625 3.75H17.4375C17.7188 3.75 18 3.50391 18 3.1875V1.5C18 0.902344 17.4727 0.375 16.875 0.375Z"
                            fill="currentColor"
                        ></path>
                    </svg>

                    <h3 className="central-container__filter-button-name">
                        {Language.getTitleCase("unanswered")}
                    </h3>
                </div>
            </div>

            <div className="user-portal__nav">
                <div className="user-portal__filter-wrapper">
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenCategories((prev) => !prev)}
                    >
                        {Language.getTitleCase("category")}
                    </span>
                    <div
                        className={`user-portal__filter-button user-portal__filter--leaderboard user-portal__filter-button expand ${
                            openCategories ? "active" : ""
                        }`}
                    >
                        <div
                            id="show_departmentFilter"
                            onClick={() => setOpenCategories((prev) => !prev)}
                        >
                            <svg
                                width="12"
                                height="20"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.5332 5.50977 C1.00586 5.50977 0.742188 4.86524 1.12305 4.48438 L4.87305 1.12305 C5.10742 0.5 5.48828 0.5 5.72266 1.12305 L9.47266 4.48438 C9.85352 4.86524 9.58984 5.50977 9.0625 5.50977 H1.5332 Z"
                                    fill="black"
                                ></path>
                            </svg>
                        </div>

                        {openCategories && (
                            <Categories>
                                {categories?.map((categorie) => (
                                    <li
                                        key={categorie.uid}
                                        className={`user-portal__filter user-portal__filter--department`}
                                    >
                                        <div className="user-portal__filter-checkbox">
                                            <input
                                                onClick={handleChange}
                                                id="category_uid"
                                                value={categorie.uid}
                                                checked={
                                                    categorie.uid ===
                                                    state.category_uid
                                                }
                                                type="checkbox"
                                                aria-label="checkbox"
                                                readOnly
                                            />
                                            <label
                                                htmlFor={
                                                    "department-filter--" +
                                                    categorie.name.toLowerCase()
                                                }
                                            >
                                                {categorie.name}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                                <a
                                    onClick={handleCategoryClear}
                                    style={{ cursor: "pointer" }}
                                >
                                    {Language.getTitleCase("Clear All")}
                                </a>
                            </Categories>
                        )}
                    </div>
                </div>

                <div className="user-portal__filter-wrapper">
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenOrder((prev) => !prev)}
                    >
                        {Language.getTitleCase("Order By")}
                    </span>
                    <div
                        className={`user-portal__filter-button user-portal__filter--leaderboard user-portal__filter-button expand ${
                            openOrder ? "active" : ""
                        }`}
                    >
                        <div
                            id="show_departmentFilter"
                            onClick={() => setOpenOrder((prev) => !prev)}
                        >
                            <svg
                                width="12"
                                height="20"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.5332 5.50977 C1.00586 5.50977 0.742188 4.86524 1.12305 4.48438 L4.87305 1.12305 C5.10742 0.5 5.48828 0.5 5.72266 1.12305 L9.47266 4.48438 C9.85352 4.86524 9.58984 5.50977 9.0625 5.50977 H1.5332 Z"
                                    fill="black"
                                ></path>
                            </svg>
                        </div>

                        {openOrder && (
                            <Categories>
                                {orders?.map((order) => (
                                    <li
                                        key={order.id}
                                        className={`user-portal__filter user-portal__filter--department`}
                                    >
                                        <div className="user-portal__filter-checkbox">
                                            <input
                                                onClick={handleChange}
                                                id="order_id"
                                                value={order.id}
                                                checked={
                                                    order.id == state.order_id
                                                }
                                                type="checkbox"
                                                aria-label="checkbox"
                                                readOnly
                                            />
                                            <label
                                                htmlFor={
                                                    "department-filter--" +
                                                    order.name.toLowerCase()
                                                }
                                            >
                                                {order.name}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </Categories>
                        )}
                    </div>
                </div>

                <Drip7Input
                    name="search"
                    className='hidden sm:block'
                    placeholder={Language.getTitleCase('search')}
                    value={search}
                    onChange={handleChange}>
                    <MagnifyingGlassIcon className='w-6 h-6' />
                </Drip7Input>
                {/*}
                <InputWithIcon
                    inputProps={{
                        type: "text",
                        placeholder: Language.getSentenceCase("search") + "...",
                        style: { minWidth: 300 },
                        value: search,
                        onChange: (e) =>
                            setState({
                                ...state,
                                search: e.target.value.toLowerCase(),
                            }),
                    }}
                    icon="search-icon"
                />
                {*/}
            </div>

            <div className="central-container question-archive">
                <div className="mx-2">
                    <Drip7Input
                        name="search"
                        className='w-full block sm:hidden'
                        placeholder={Language.getTitleCase('search')}
                        value={search}
                        onChange={handleChange}>
                        <MagnifyingGlassIcon className='w-6 h-6' />
                    </Drip7Input>
                    {/*}
                    <InputWithIcon
                        fullWidth
                        inputProps={{
                            type: "text",
                            placeholder:
                                Language.getSentenceCase("search") + "...",
                            value: search,
                            onChange: (e) =>
                                setState({
                                    ...state,
                                    search: e.target.value.toLowerCase(),
                                }),
                        }}
                        icon="search-icon"
                    />
                    {*/}
                </div>

                <div className="central-container__filter-button-container central-container__filter-button-container--question-archive">
                    <div
                        className={klassAnswerType(false)}
                        onClick={() => handleAnswerType(false)}
                    >
                        <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 2.21875V10.6562C0 11.1484 0.351562 11.5 0.84375 11.5H3.65625C4.11328 11.5 4.5 11.1484 4.5 10.6562V2.21875C4.5 1.76172 4.11328 1.375 3.65625 1.375H0.84375C0.351562 1.375 0 1.76172 0 2.21875ZM1.40625 9.25C1.40625 8.79297 1.75781 8.40625 2.25 8.40625C2.70703 8.40625 3.09375 8.79297 3.09375 9.25C3.09375 9.74219 2.70703 10.0938 2.25 10.0938C1.75781 10.0938 1.40625 9.74219 1.40625 9.25ZM10.9688 18.25C11.8125 18.25 13.5 17.9688 13.5 15.4023C13.5 13.9258 12.5859 13.082 12.3047 12.0625H15.8906C17.0508 12.0625 17.9648 11.1133 18 10.0234C18 9.39062 17.7188 8.72266 17.2969 8.30078C17.6484 7.49219 17.5781 6.33203 16.9805 5.52344C17.2617 4.60938 16.9805 3.48438 16.3828 2.88672C16.5586 2.25391 16.4883 1.72656 16.1719 1.30469C15.4688 0.285156 13.6758 0.25 12.1641 0.25H12.0586C10.3711 0.285156 9 0.882812 7.875 1.375C7.3125 1.62109 6.57422 1.9375 6.01172 1.9375C5.80078 1.97266 5.625 2.14844 5.625 2.35938V9.88281C5.625 9.98828 5.66016 10.1289 5.73047 10.1992C7.13672 11.5703 7.73438 13.0117 8.85938 14.1719C9.38672 14.6992 9.5625 15.4727 9.77344 16.2461C9.91406 16.8789 10.2305 18.25 10.9688 18.25Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                        <h3 className="central-container__filter-button-name">
                            {Language.getTitleCase("incorrect")}
                        </h3>
                    </div>

                    <div
                        className={klassAnswerType(true)}
                        onClick={() => handleAnswerType(true)}
                    >
                        <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.65625 8.125H0.84375C0.351562 8.125 0 8.51172 0 8.96875V17.4062C0 17.8984 0.351562 18.25 0.84375 18.25H3.65625C4.11328 18.25 4.5 17.8984 4.5 17.4062V8.96875C4.5 8.51172 4.11328 8.125 3.65625 8.125ZM2.25 16.8438C1.75781 16.8438 1.40625 16.4922 1.40625 16C1.40625 15.543 1.75781 15.1562 2.25 15.1562C2.70703 15.1562 3.09375 15.543 3.09375 16C3.09375 16.4922 2.70703 16.8438 2.25 16.8438ZM13.5 3.13281C13.5 0.53125 11.8125 0.25 10.9688 0.25C10.2305 0.25 9.91406 1.65625 9.77344 2.28906C9.5625 3.0625 9.38672 3.83594 8.85938 4.36328C7.73438 5.52344 7.13672 6.96484 5.73047 8.33594C5.66016 8.44141 5.625 8.54688 5.625 8.65234V16.1758C5.625 16.3867 5.80078 16.5625 6.01172 16.5977C6.57422 16.5977 7.3125 16.9141 7.875 17.1602C9 17.6523 10.3711 18.25 12.0586 18.25H12.1641C13.6758 18.25 15.4688 18.25 16.1719 17.2305C16.4883 16.8086 16.5586 16.2812 16.3828 15.6484C16.9805 15.0508 17.2617 13.9258 16.9805 13.0117C17.5781 12.2031 17.6484 11.043 17.2969 10.2344C17.7188 9.8125 18 9.14453 17.9648 8.51172C17.9648 7.42188 17.0508 6.4375 15.8906 6.4375H12.3047C12.5859 5.45312 13.5 4.60938 13.5 3.13281Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                        <h3 className="central-container__filter-button-name">
                            {Language.getTitleCase("correct")}
                        </h3>
                    </div>

                    {false && (
                        <>
                            <div
                                className={klassAnswerType(null)}
                                onClick={() => handleAnswerType(null)}
                            >
                                <svg
                                    width="18"
                                    height="17"
                                    viewBox="0 0 18 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.125 15C1.125 15.6328 1.61719 16.125 2.25 16.125H15.75C16.3477 16.125 16.875 15.6328 16.875 15V4.875H1.125V15ZM6.75 7.54688C6.75 7.33594 6.92578 7.125 7.17188 7.125H10.8281C11.0391 7.125 11.25 7.33594 11.25 7.54688V7.82812C11.25 8.07422 11.0391 8.25 10.8281 8.25H7.17188C6.92578 8.25 6.75 8.07422 6.75 7.82812V7.54688ZM16.875 0.375H1.125C0.492188 0.375 0 0.902344 0 1.5V3.1875C0 3.50391 0.246094 3.75 0.5625 3.75H17.4375C17.7188 3.75 18 3.50391 18 3.1875V1.5C18 0.902344 17.4727 0.375 16.875 0.375Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>

                                <h3 className="central-container__filter-button-name">
                                    {Language.getTitleCase("unanswered")}
                                </h3>
                            </div>
                        </>
                    )}
                </div>

                <div className="question-archive__body">
                    {!loaded && (
                        <div className="question-archive__loader">
                            {[...Array(10)].map((x, i) => (
                                <div
                                    className="question-archive__loader--item"
                                    key={"loading" + i}
                                >
                                    <ContentLoader width="550" height="114">
                                        <rect
                                            x="0"
                                            y="16"
                                            width="80"
                                            height="13"
                                        />
                                        <rect
                                            className="mobile-row"
                                            x="0"
                                            y="45"
                                            width="550"
                                            height="14"
                                        />
                                        <rect
                                            x="0"
                                            y="90"
                                            rx="12"
                                            ry="12"
                                            width="168"
                                            height="24"
                                        />
                                    </ContentLoader>
                                </div>
                            ))}
                        </div>
                    )}
                    {false &&
                        answer_type == false &&
                        searched_questions.length >= 7 && (
                            <div className="question-archive__item">
                                <button
                                    className="progress-home__btn blue-button"
                                    onClick={() =>
                                        handleCreateQuiz(
                                            searched_questions.map((x) => x.uid)
                                        )
                                    }
                                >
                                    {Language.getSentenceCase(
                                        "Create a pop quiz"
                                    )}
                                </button>
                            </div>
                        )}
                    {Object.entries(searched_questions).map(
                        ([key, user_question]) => (
                            <div key={key} className="question-archive__item">
                                {user_question.answered_on > 0 && (
                                    <h6 className="question-archive__date">
                                        {Util.epochToDate(
                                            user_question.answered_on
                                        )}
                                    </h6>
                                )}
                                <h4 className="question-archive__question">
                                    {user_question.question.name || " "}
                                </h4>
                                {answer_loaded ? (
                                    <h4 className="question-archive__answer">
                                        {" "}
                                        {answer_key[user_question.uid] ||
                                            "No answer recorded"}
                                    </h4>
                                ) : (
                                    <div className="question-archive__loader--answer"></div>
                                )}

                                <div className="question-archive__information">
                                    <h4 className="question-archive__category">
                                        {
                                            category_lookup[
                                                user_question.category_uid
                                            ].name
                                        }
                                    </h4>

                                    <h6 className="question-archive__points">
                                        {user_question.points}{" "}
                                        {user_question.points != 1
                                            ? Language.getString("points")
                                            : Language.getString("point")}{" "}
                                        {Language.getLowerCase("earned")}
                                    </h6>
                                </div>
                            </div>
                        )
                    )}
                    {state.loaded === true && searched_questions.length <= 0 ? (
                        <div className="no-result">
                            <img src={ResultIMG} alt="Result" />
                            <h2>
                                {Language.getSentenceCase("Keep trying")}!
                                <br />
                                {Language.getSentenceCase(
                                    "no questions answered in this category"
                                )}
                            </h2>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
