import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";

import Util from "../../helpers/util";
import Language from "../../helpers/language";
import parse from "html-react-parser";

export const InformationPopup = (props) => {
    const { explanation, onClose } = props;
    const scrollableContainerRef = useRef(null);

    useEffect(() => {
        if ( scrollableContainerRef.current != null ) {
            scrollableContainerRef.current.scrollTop = 0;
        }
    });

    return (
        <div className="up-popup__container">
            <div className="up-popup up-popup--info bg-white">
                <div className="up-popup__icon-wrapper">
                    <div className="up-popup__icon-wrapper--cropped"></div>
                </div>

                <div className="up-popup__icon">
                    <svg
                        width="28"
                        height="45"
                        viewBox="0 0 28 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.8836 0.873205C19.6024 1.65707 21.8573 2.8485 23.6483 4.44751C25.4907 6.01806 26.7381 7.85011 27.3904 9.94367C28.0827 12.0488 28.1061 14.2208 27.4606 16.4599C26.5499 19.6186 24.778 22.0092 22.1448 23.632C19.5116 25.2547 16.2613 25.6384 12.3937 24.7832L11.1833 28.9815C11.0104 29.5812 10.6606 30.0433 10.134 30.3679C9.60738 30.6924 9.04419 30.7682 8.44444 30.5953L7.24494 30.2495C6.64519 30.0766 6.20881 29.7126 5.93577 29.1575C5.66274 28.6024 5.61268 28.025 5.78559 27.4253L7.5147 21.4278C7.71067 20.7481 8.04621 20.2602 8.52134 19.9641C9.03644 19.6795 9.61386 19.6295 10.2536 19.8139L11.3331 20.1251C14.2519 20.9666 16.567 20.8979 18.2784 19.9188C20.0413 18.9114 21.2512 17.2681 21.9083 14.9891C22.5192 12.8699 22.2837 10.9832 21.2017 9.32869C20.1311 7.63424 18.3764 6.43543 15.9374 5.73225C13.9382 5.15588 12.1867 4.99736 10.6828 5.25669C9.17896 5.51602 7.96846 6.18473 7.05134 7.26283C7.61506 8.16156 7.73553 9.1707 7.41276 10.2902C7.12458 11.2898 6.5684 12.0172 5.74423 12.4725C4.93159 12.8878 4.00549 12.9456 2.96592 12.6459C1.80641 12.3116 0.996305 11.6667 0.535611 10.711C0.114899 9.7669 0.0889819 8.65511 0.457859 7.37565C1.03423 5.37649 2.10696 3.75862 3.67606 2.52204C5.25669 1.24548 7.19338 0.461329 9.48612 0.169595C11.7789 -0.12214 14.2447 0.112397 16.8836 0.873205ZM6.45222 35.4776C7.6917 35.8349 8.59599 36.5287 9.16511 37.5589C9.78574 38.5607 9.91738 39.6813 9.56003 40.9208C9.20268 42.1603 8.4889 43.0588 7.41871 43.6164C6.3885 44.1855 5.25365 44.2914 4.01417 43.9341C2.77469 43.5767 1.8504 42.8772 1.2413 41.8354C0.672184 40.8052 0.5663 39.6704 0.923649 38.4309C1.281 37.1914 1.96902 36.3071 2.9877 35.778C4.0579 35.2204 5.21274 35.1202 6.45222 35.4776Z"
                            fill="currentColor"
                        ></path>
                    </svg>

                    <svg
                        className="up-popup__icon--shadow"
                        width="28"
                        height="45"
                        viewBox="0 0 28 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.8836 0.873205C19.6024 1.65707 21.8573 2.8485 23.6483 4.44751C25.4907 6.01806 26.7381 7.85011 27.3904 9.94367C28.0827 12.0488 28.1061 14.2208 27.4606 16.4599C26.5499 19.6186 24.778 22.0092 22.1448 23.632C19.5116 25.2547 16.2613 25.6384 12.3937 24.7832L11.1833 28.9815C11.0104 29.5812 10.6606 30.0433 10.134 30.3679C9.60738 30.6924 9.04419 30.7682 8.44444 30.5953L7.24494 30.2495C6.64519 30.0766 6.20881 29.7126 5.93577 29.1575C5.66274 28.6024 5.61268 28.025 5.78559 27.4253L7.5147 21.4278C7.71067 20.7481 8.04621 20.2602 8.52134 19.9641C9.03644 19.6795 9.61386 19.6295 10.2536 19.8139L11.3331 20.1251C14.2519 20.9666 16.567 20.8979 18.2784 19.9188C20.0413 18.9114 21.2512 17.2681 21.9083 14.9891C22.5192 12.8699 22.2837 10.9832 21.2017 9.32869C20.1311 7.63424 18.3764 6.43543 15.9374 5.73225C13.9382 5.15588 12.1867 4.99736 10.6828 5.25669C9.17896 5.51602 7.96846 6.18473 7.05134 7.26283C7.61506 8.16156 7.73553 9.1707 7.41276 10.2902C7.12458 11.2898 6.5684 12.0172 5.74423 12.4725C4.93159 12.8878 4.00549 12.9456 2.96592 12.6459C1.80641 12.3116 0.996305 11.6667 0.535611 10.711C0.114899 9.7669 0.0889819 8.65511 0.457859 7.37565C1.03423 5.37649 2.10696 3.75862 3.67606 2.52204C5.25669 1.24548 7.19338 0.461329 9.48612 0.169595C11.7789 -0.12214 14.2447 0.112397 16.8836 0.873205ZM6.45222 35.4776C7.6917 35.8349 8.59599 36.5287 9.16511 37.5589C9.78574 38.5607 9.91738 39.6813 9.56003 40.9208C9.20268 42.1603 8.4889 43.0588 7.41871 43.6164C6.3885 44.1855 5.25365 44.2914 4.01417 43.9341C2.77469 43.5767 1.8504 42.8772 1.2413 41.8354C0.672184 40.8052 0.5663 39.6704 0.923649 38.4309C1.281 37.1914 1.96902 36.3071 2.9877 35.778C4.0579 35.2204 5.21274 35.1202 6.45222 35.4776Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>

                <div
                    className="up-popup__scrollable-container"
                    ref={scrollableContainerRef}
                >
                    <p className="up-popup__p text-gray-600">
                        {parse(Util.hyperlink(explanation.info))}
                    </p>
                     </div>
               
                    <button
                        className="up-popup__button up-popup__button--close ring-1 ring-drip7 focus:outline-none"
                        onClick={onClose}
                    >
                        {Language.getTitleCase("close")}
                    </button>
            </div>
        </div>
    );
};
