import React, { useEffect, useRef } from "react";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import plus from "../../assets/images/user-portal/sm-plus.svg"
import check from "../../assets/images/user-portal/sm-check.svg"
import Language from "../../helpers/language";
import answer_x from "../../assets/images/shared/close-red.svg";


export const PreviousTraining = (props) => {
    const { language, onIndex } = props;

    const questions = [...props.questions].reverse()
    const endSlide = questions.length - 1
    
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate()-1)

    const sameday = (d1,d2) => {
        return d1.getFullYear() == d2.getFullYear() &&
            d1.getMonth() == d2.getMonth() &&
            d1.getDate() == d2.getDate();
    }


    // Get the most recent 7 questions, then sort oldest to newest
    //const question_copy = questions.slice(0, 7);
    //question_copy.sort((q) => -q.assigned_on)

    return (
        <span className="prev-content--container">
            {questions.length === 0 &&
                <Splide
                    hasTrack={ false }
                    options={ {
                        start: 0,
                        drag:'free',
                        rewind: true,
                        arrows:false,
                        pagination:false,
                        snap:true,
                        width : '80vm',

                    } }>
                    <SplideTrack>
                        <SplideSlide>
                            <div className="card desktop-card" style={{height: '224px'}}>
                                <h3 className="card-title">Empty</h3>
                                <div className="center-divider">
                                    <div className="card-divider"></div>
                                </div>
                                <div className="card-question"></div>
                            </div>
                        </SplideSlide>
                    </SplideTrack>
                </Splide>
            }
            {questions.length > 0 &&
            <Splide
            hasTrack={ false }
            onMoved={x => onIndex(endSlide - x.index)}
            onScrolled={x => onIndex(endSlide - x.index)}
            options={ {
                start: endSlide,
                drag:'free',
                rewind: true,
                arrows:false,
                pagination:false,
                snap:true,
                width : '80vw',
                
            } }>
                <SplideTrack>
                    <div className="test" style={{width:'100px'}}></div>
                    {questions.map((question,idx)=>{
                        const assigned = new Date(question.assigned_on);
                        let dayString = '';
                        if (sameday(today, assigned))
                            dayString = Language.getSentenceCase('today');
                        else if (sameday(yesterday, assigned))
                            dayString = Language.getSentenceCase('yesterday');
                        else
                            dayString = assigned.toLocaleDateString(language, {'month':'long', 'day':'numeric'})
                        return(<SplideSlide key={idx}>                
                            <div className="card userdash-component card-slide mr-4 shadow-md" id={questions.length-1 == idx?'last-slide':0 == idx?'first-slide':''}>
                                <h3 className="card-title">{dayString}</h3>
                                <div className="center-divider">
                                    <div className="card-divider"></div>
                                </div> 
                                <div className="card-question">{question.question.name}</div>
                                <div className="card-answer-div">
                                    {!question.unanswered &&
                                        <div className={` card-answer card-answer-${question.correct}`}>{question.answer.response_name}</div>
                                    }
                                </div>
                                {question.unanswered &&
                                    <div className="card-results">
                                        <span className="card-results-container">
                                            <span className="card-point"><i>{Language.getSentenceCase('unanswered')}</i></span>
                                        </span>
                                    </div>
                                }
                                {!question.unanswered &&
                                    <div className="card-results">
                                        <span className="card-results-container align-items-center">
                                            {question.correct ? <img src={check} className="w-4 h-4 mr-1"/> : <img src={answer_x} className="w-4 h-4 mr-1"/>}
                                            <span className="card-point">{question.correct?Language.getSentenceCase('Correct'):Language.getSentenceCase('Incorrect')}</span>
                                        </span>
                                        <span className="card-results-container align-items-center">
                                            <img src={plus} className="w-4 h-4 mr-1"/>

                                            <span
                                                className="card-point">{question.points >= 1 ? question.points + " " + Language.getSentenceCase('Points') : question.points + " " + Language.getSentenceCase('Point')}</span>

                                        </span>
                                    </div>
                                }
                            </div>
                        </SplideSlide>  
                    )})}
                </SplideTrack>
            </Splide>
        }
      </span>

    );
  };
  